var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col",attrs:{"data-testid":"bisaBelanja-brand-detail"}},[(_vm.brandController.isLoading)?_c('LoadingOverlay',{attrs:{"data-testid":"bisaBelanja-brand-detail__loader"}}):_vm._e(),_c('h2',{staticClass:"text-3xl font-semibold border-b pb-2"},[_vm._v("Brand")]),_c('div',{staticClass:"text-xl mb-5 font-medium border-b py-4 pl-6 flex justify-between content-center"},[_c('div',{staticClass:"self-center w-6/12 flex justify-between"},[_c('div',[_c('span',{staticClass:"text-muted mr-2"},[_vm._v("Brand:")]),_c('span',[_vm._v(_vm._s(_vm.brandController.brandDetail.name))])]),_c('div',[_c('span',{staticClass:"text-muted mr-2"},[_vm._v("Merchant Count:")]),_c('span',[_vm._v(_vm._s(_vm.merchantController.paginationData.totalItem))])])]),_c('div',{staticClass:"text-base"},[_c('router-link',{attrs:{"data-testid":("bisaBelanja-brand-detail__event-create-merchant__brand-" + (_vm.$route.params.id)),"to":{ name: 'CreateMerchant', params: { id: _vm.$route.params.id } }}},[_c('Button',{staticClass:"mr-4"},[_vm._v("Create Merchant")])],1)],1)]),_c('div',{staticClass:"mt-3"},[_c('div',{staticClass:"w-full overflow-x-auto"},[_c('DataTable',{attrs:{"data-testid":"bisaBelanja-brand-detail__data-table","isLoading":_vm.brandController.isLoading,"headers":_vm.headers,"data":_vm.merchantData},scopedSlots:_vm._u([{key:"2",fn:function(ref){
var data = ref.data;
return [_c('div',{staticClass:"flex justify-center"},[_c('router-link',{attrs:{"data-testid":("bisaBelanja-brand-detail__event-merchant-detail__" + (data.id)),"to":{
                name: 'MerchantDetailProfilePage',
                params: { id: data.id },
              }}},[_c('eye')],1)],1)]}}])})],1),_c('PaginationNav',{attrs:{"data-testid":"bisaBelanja-brand-detail__pagination","page":_vm.parameters.page,"perPage":_vm.parameters.perPage,"totalItem":_vm.merchantController.paginationData.totalItem},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return (function () {
          _vm.fetchMerchantList()
        })($event)},"input":function () {
          _vm.fetchMerchantList()
        }},model:{value:(_vm.parameters.page),callback:function ($$v) {_vm.$set(_vm.parameters, "page", $$v)},expression:"parameters.page"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }