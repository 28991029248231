












































































import { Component, Vue, Watch } from 'vue-property-decorator'
import Button from '@/app/ui/components/Button/index.vue'
import brandController from '@/app/ui/controllers/BrandController'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import DataTable from '@/app/ui/components/DataTable/index.vue'
import Eye from '@/app/ui/assets/eye.vue'
import PaginationNav from '@/app/ui/components/PaginationNav/index.vue'
import { MERCHANT_PAGINATION } from '@/app/infrastructures/misc/Constants/pagination'
import merchantController from '@/app/ui/controllers/MerchantController'
import { Utils } from '@/app/infrastructures/misc'
import { Merchant } from '@/domain/entities/Merchant'
import { Dictionary } from 'vue-router/types/router'

@Component({
  components: {
    Button,
    LoadingOverlay,
    DataTable,
    PaginationNav,
    Eye,
  },
})
export default class DetailBrand extends Vue {
  brandController = brandController
  merchantController = merchantController

  parameters: { page: number; perPage: number } = {
    page: 1,
    perPage: MERCHANT_PAGINATION,
  }

  headers: Array<string | Record<string, string>> = [
    'Merchant ID',
    'Merchant Name',
    { title: 'Action', width: '10%', class: 'text-center' },
  ]

  created(): void {
    if (this.$route.query instanceof Object) {
      const queries = <Record<string, never>>this.$route.query
      this.parameters = {
        page: Utils.alwaysNumber(queries.page) || 1,
        perPage: Utils.alwaysNumber(queries.perPage) || MERCHANT_PAGINATION,
      }
    }

    brandController.getBrandDetail(this.$route.params.id)
    this.fetchMerchantList()
  }

  get merchantData(): Array<Array<string | number | Merchant | undefined>> {
    return merchantController.merchantData.map(merchant => [
      merchant.id,
      merchant.name,
      merchant,
    ])
  }

  private fetchMerchantList(): void {
    merchantController.getMerchantList({
      brandId: this.$route.params.id,
      ...this.parameters,
    })
  }

  @Watch('parameters')
  onParamsChanged(val: Dictionary<string | (string | null)[] | null | undefined> | undefined): void {
    this.$router.replace({
      query: { ...val },
    })
  }
}
